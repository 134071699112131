import { ElementType, FC, ReactNode, useState, useRef, memo, useEffect } from 'react';
import { useClickAway, useEffectOnce, useUpdateEffect } from 'react-use';
import styled from 'styled-components';
import { resetButtonStyle } from '@styles/placeholders';

export type AccordionProps = {
  pane: ReactNode;
  component?: ElementType;
  className?: string;
  expanded?: boolean;
  isClickedAway?: boolean;
  duration?: number;
  onExpanded?: () => void;
  onExpandStart?: () => void;
  onCollapsed?: () => void;
  onCollapseStart?: () => void;
};

export type ContentProps = Pick<AccordionProps, 'duration'> & {
  heightValue: number;
  isExpanded: boolean;
};

const Wrapper = styled.div``;

const TitleInner = styled.div`
  outline: none;
`;

const Title = styled.button`
  ${resetButtonStyle};
  display: block;
  width: 100%;
  text-align: left;
  padding: 4px;
  margin-left: -4px;
  color: ${({ theme }): string => theme.colors.black};

  &:focus {
    > ${TitleInner} {
      outline: 1px dashed ${({ theme }): string => theme.colors.lightYellow};
    }
  }

  &:active {
    outline: none;
  }
`;

const Content = styled.div<ContentProps>`
  overflow: ${({ isExpanded }): string => (isExpanded ? 'initial' : 'hidden')};
  height: ${({ heightValue }): string => heightValue && `${heightValue}px`};
  transition: height ${({ duration }): string => `${duration}ms`} ease;
`;

const AccordionComponent: FC<AccordionProps> = ({
  children,
  className,
  pane,
  component,
  expanded = false,
  isClickedAway = false,
  duration = 300,
  onExpanded = Function.prototype,
  onExpandStart = Function.prototype,
  onCollapsed = Function.prototype,
  onCollapseStart = Function.prototype,
}): JSX.Element => {
  const wrapperRef = useRef<HTMLElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const [isExpanded, setIsExpanded] = useState<boolean>(expanded);
  const [active, setActive] = useState<boolean>(expanded);
  const [height, setHeight] = useState<number | null>(expanded ? null : 0);

  const hideContent = (): void => {
    setHeight(0);
  };

  const showContent = (): void => {
    if (height === 0) {
      contentRef.current.style.height = 'auto';

      const newHeightValue = contentRef.current.clientHeight;

      contentRef.current.style.height = '0px';
      contentRef.current.removeAttribute('style');

      setTimeout(() => {
        setHeight(newHeightValue);
      }, 0);
    } else {
      setHeight(0);
      setIsExpanded(false);
    }
  };

  useClickAway(wrapperRef, () => {
    if (isClickedAway) {
      hideContent();
    }
  });

  useUpdateEffect(() => {
    if (!expanded) {
      hideContent();
    }
  }, [expanded]);

  useEffectOnce(() => {
    setHeight(contentRef.current.clientHeight);
  });

  useEffect(() => {
    const { current } = contentRef;
    const onCollapseStartEffect = (event: TransitionEvent): void => {
      if (event.target === contentRef.current) {
        onCollapseStart();
        setIsExpanded(false);
        setActive(false);
      }
    };
    const onExpandStartEffect = (event: TransitionEvent): void => {
      if (event.target === contentRef.current) {
        onExpandStart();
        setActive(true);
      }
    };
    const onTransitionStart = (e): void => (active ? onCollapseStartEffect(e) : onExpandStartEffect(e));

    current.addEventListener('transitionstart', onTransitionStart);

    return (): void => {
      current.removeEventListener('transitionstart', onTransitionStart);
    };
  }, [active, onExpandStart, onCollapseStart]);

  return (
    <Wrapper as={component} className={className} ref={wrapperRef}>
      <Title type="button" onClick={showContent}>
        <TitleInner tabIndex={-1}>{pane}</TitleInner>
      </Title>
      <Content
        ref={contentRef}
        heightValue={height}
        duration={duration}
        isExpanded={isExpanded}
        onTransitionEnd={
          height === 0
            ? (e): void => {
                if (e.target === contentRef.current) {
                  onCollapsed();
                }
              }
            : (e): void => {
                if (e.target === contentRef.current) {
                  onExpanded();
                  setIsExpanded(true);
                }
              }
        }
      >
        {children}
      </Content>
    </Wrapper>
  );
};

export const Accordion = memo<FC<AccordionProps>>(AccordionComponent);
