import { FC } from 'react';
import styled from 'styled-components';
import { ContainerWidth, BreakpointsCssProperties } from '@styles/types';
import { sortCssBreakpointsProperties, getAdaptiveStyles } from '@styles/utils';

export type ContainerProps = {
  maxWidth?: keyof ContainerWidth;
  fixed?: boolean;
  disableGutters?: boolean;
  fullWidth?: boolean;
  className?: string;
};

const defaultContainerPadding = 40;

const Wrapper = styled.div<ContainerProps>`
  position: relative;

  ${({ theme, maxWidth, disableGutters, fullWidth }): string =>
    `max-width: ${
      fullWidth ? '100' : theme.containersWidth[maxWidth] + (disableGutters ? 0 : defaultContainerPadding * 2)
    }${fullWidth ? '%' : 'px'}`};
  ${({ theme, maxWidth, fixed, disableGutters }): string | false =>
    fixed && `min-width: ${theme.containersWidth[maxWidth] + (disableGutters ? 0 : defaultContainerPadding * 2)}px`};

  ${({ disableGutters }): string | false =>
    !disableGutters &&
    `
    padding-right: ${defaultContainerPadding}px;
    padding-left: ${defaultContainerPadding}px;
  `};

  width: 100%;
  margin-right: auto;
  margin-left: auto;

  ${(props): string => getAdaptiveStyles(props)};
`;

export const Container: FC<ContainerProps & BreakpointsCssProperties> = ({
  children,
  maxWidth = 'xl',
  fixed = false,
  disableGutters = false,
  fullWidth = false,
  className,
  ...props
}): JSX.Element => (
  <Wrapper
    fullWidth={fullWidth}
    maxWidth={maxWidth}
    fixed={fixed}
    disableGutters={disableGutters}
    {...sortCssBreakpointsProperties(props)}
    className={className}
  >
    {children}
  </Wrapper>
);
