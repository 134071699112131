import { FC } from 'react';
import { Container } from './components/Container';
import { Header, Footer, Services, HowItWorks, Capabilities, FAQ, About } from './sections';

export const MainPage: FC = () => {
  return (
    <>
      <Header />
      <Container>
        <Services />
        <HowItWorks />
        <Capabilities />
        <FAQ />
        <About />
      </Container>
      <Footer />
    </>
  );
};
