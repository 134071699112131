import { FC, useEffect, useRef, useState, createRef, RefObject } from 'react';
import { Element } from 'react-scroll';
import { Typography, TypographyVariants } from '@components/Typography';
import { Grid } from '@components/Grid';
import { useScreenSize } from '@hooks/useScreenSize';
import { data } from './data';
import { Capability, CapabilityRef } from './components/Capability';
import { NextBlockLink } from '../../../../common/components/NextBlockLink';

export const Capabilities: FC = () => {
  const [height, setHeight] = useState<number>(0);
  const [padding, setPadding] = useState<number>(0);
  const { sm } = useScreenSize();
  const contentRef = useRef<Array<RefObject<CapabilityRef>>>([]);
  const generateHeight = (): void => {
    if (contentRef.current.length === data.length) {
      const maxHeight = contentRef.current.reduce((acc, item) => {
        if (item.current !== null && item.current.clientHeight > acc) {
          return item.current.clientHeight;
        }

        return acc;
      }, 0);
      setHeight(maxHeight + 2 * padding);
    }
  };

  useEffect(() => {
    setPadding(sm ? 20 : 40);
  }, [sm]);

  if (contentRef.current.length !== data.length) {
    contentRef.current = Array.from(
      { length: data.length },
      (v, i) => contentRef.current[i] || createRef<CapabilityRef>(),
    );
  }

  useEffect(() => {
    generateHeight();

    window.addEventListener('resize', generateHeight);

    return (): void => {
      window.removeEventListener('resize', generateHeight);
    };
  }, [height]);

  return (
    <section>
      <NextBlockLink to="capabilities">Что входит в дизайн-проект</NextBlockLink>

      <Element name="capabilities">
        <Typography variant={TypographyVariants.Title} component="h2">
          Возможности конструктора
        </Typography>
        <Grid
          columns={4}
          lg={{ columns: 2 }}
          md={{ columns: 1, gapX: 20, gapY: 20 }}
          gapX={40}
          gapY={40}
          alignItems="flex-start"
        >
          {data.map((item, index) => (
            <Capability ref={contentRef.current[index]} height={height} data={item} padding={padding} key={index} />
          ))}
        </Grid>
      </Element>
    </section>
  );
};
