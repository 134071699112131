import { FC } from 'react';
import styled from 'styled-components';
import { Element } from 'react-scroll';
import { Box } from '@components/Box';
import { Grid } from '@components/Grid';
import { Icon } from '@components/Icon';
import { Typography, TypographyVariants } from '@components/Typography';
import { ButtonVariant, Link } from '@components/Button';
import { media } from '@styles/utils';
import pdf from '@static/documents/interiumExample.pdf';
import drawingPdf from '@static/documents/export-drawings.pdf';

import { useScreenSize } from '@hooks/useScreenSize';
import { REQUEST_APPLICATION_LINK, CREATE_PROJECT_LINK } from '../constants';

const Info = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 320px;
  height: 308px;

  ${media.md`
     width: 100%;
     height: auto;
     padding: 20px 40px;
     background-color: ${({ theme }): string => theme.colors.black};
  `}

  ${media.sm`
    padding: 20px;
  `}

  &::before {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    width: 640px;
    height: 640px;
    content: '';
    border-radius: 50%;
    transform: translate(-50%, -50%);
    background-color: ${({ theme }): string => theme.colors.black};

    ${media.md`
      content: none;
    `}
  }
`;

const EducationLink = styled(Link)`
  padding: 6px 8px;
  font-size: ${({ theme }): string => theme.fonts.sizes.sm};
  font-weight: normal;
  letter-spacing: 0.8px;
  background-color: ${({ theme }): string => theme.colors.white};}
`;

const Item = styled.section`
  position: relative;
  height: 580px;
  overflow: hidden;
  color: ${({ theme }): string => theme.colors.white};
  background-image: url('/img/landing/girl.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  border-radius: ${({ theme }): string => theme.borderRadius.lg};

  &:nth-child(2) {
    ${Info} {
      &::before {
        background-color: ${({ theme }): string => theme.colors.malachite};
      }

      ${media.md`
         background-color: ${({ theme }): string => theme.colors.malachite};
      `}
    }
  }

  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    background-image: url('/img/landing/girl@2x.png');
  }

  ${media.xl`
    height: 550px;
    background-position: center top;
  `};

  ${media.lg`
    height: 348px;
    background-position: center top;
  `};

  @media (max-width: 800px) {
    background-size: cover;
    background-position: center center;
  }
`;

const EndItem = styled(Item)`
  background-color: ${({ theme }): string => theme.colors.lightMalachite};
  background-image: url('/img/landing/constructor-user.png');

  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    background-image: url('/img/landing/constructor-user@2x.png');
  }

  ${Info} {
    &::after {
      background-color: ${({ theme }): string => theme.colors.malachite};
    }
  }
`;

const StyledIcon = styled(Icon)`
  position: relative;
  margin-bottom: 20px;
  color: ${({ theme }): string => theme.colors.white};

  ${media.md`
    margin-bottom: 0;
  `}
`;

export const Services: FC = () => {
  const { sm } = useScreenSize();

  return (
    <Element name="services">
      <Box>
        <Grid columns={2} gapX={20} xl={{ columns: 2, gapX: 20 }} lg={{ columns: 1, gapY: 20 }}>
          <Item>
            <Info>
              <Box
                pt="73px"
                pb="40px"
                pl="40px"
                pr="60px"
                themeColor="white"
                position="relative"
                zIndex={1}
                md={{ p: 0 }}
                height="100%"
                display="flex"
                flexDirection="column"
              >
                <Grid md={{ mb: 5, gapX: 20, templateColumns: '40px 1fr' }}>
                  <StyledIcon name="request_40x40" size={40} />
                  <Box mb={2} md={{ mb: 0 }}>
                    <Typography
                      component="h2"
                      uppercase
                      themeColor="white"
                      variant={TypographyVariants.BodyMedium}
                      withoutMargin
                    >
                      Доверить проект дизайнеру
                    </Typography>
                  </Box>
                </Grid>
                <Box mt="auto">
                  <Link href={REQUEST_APPLICATION_LINK} themeColor="terracotta" variant={ButtonVariant.Filled} noWrap>
                    Оставить заявку
                  </Link>
                </Box>
              </Box>
            </Info>
            <Box
              position="absolute"
              bottom="40px"
              right="40px"
              zIndex={1}
              lg={{
                right: '20px',
              }}
              md={{
                right: '40px',
                bottom: '155px',
              }}
              sm={{
                right: '20px',
              }}
            >
              <EducationLink target="_blank" href={pdf} themeColor="darkGray" variant={ButtonVariant.Contained}>
                Примеры работ
              </EducationLink>
            </Box>
          </Item>
          <EndItem>
            <Info>
              <Box
                pt="73px"
                pb="40px"
                pl="40px"
                pr="60px"
                themeColor="white"
                position="relative"
                zIndex={1}
                md={{ p: 0 }}
                height="100%"
                display="flex"
                flexDirection="column"
              >
                <Grid
                  alignItems="center"
                  md={{
                    mb: 5,
                    gapX: 20,
                    templateColumns: '40px 1fr',
                  }}
                >
                  <StyledIcon name="pen_40x40" size={40} />
                  <Box mb={2} md={{ mb: 0 }}>
                    <Typography
                      component="h2"
                      uppercase
                      themeColor="white"
                      variant={TypographyVariants.BodyMedium}
                      withoutMargin
                    >
                      Создать интерьер {sm && <br />} самому
                    </Typography>
                  </Box>
                </Grid>
                <Box mt="auto">
                  <Link
                    href={CREATE_PROJECT_LINK}
                    themeColor="yellow"
                    variant={ButtonVariant.Filled}
                    invertedTextColor
                    noWrap
                  >
                    Создать проект
                  </Link>
                </Box>
              </Box>
            </Info>
            <Box
              position="absolute"
              bottom="40px"
              right="40px"
              zIndex={1}
              lg={{
                right: '20px',
              }}
              md={{
                right: '40px',
                bottom: '155px',
              }}
              sm={{
                right: '20px',
              }}
            >
              <EducationLink
                target="_blank"
                href={drawingPdf}
                themeColor="malachite"
                startIcon="play"
                variant={ButtonVariant.Contained}
              >
                Возможности конструктора
              </EducationLink>
            </Box>
          </EndItem>
        </Grid>
      </Box>
    </Element>
  );
};
