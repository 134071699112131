import { ElementType, memo, ReactElement, FunctionComponent, useState, useEffect, useMemo, cloneElement } from 'react';
import styled from 'styled-components';

type Children = {
  active: number[] | null;
};

export type AccordionsGroupProps = {
  children({ active }: Children): ReactElement;
  controlled?: boolean;
  defaultExpanded?: number;
  component?: ElementType;
  className?: string;
};

const Wrapper = styled.div``;

const AccordionsGroupComponent: FunctionComponent<AccordionsGroupProps> = (props): JSX.Element => {
  const { children, controlled = false, defaultExpanded, className, component } = props;
  const hasDefaultValue = typeof defaultExpanded === 'number';
  const [active, setActive] = useState<number[]>(hasDefaultValue ? [defaultExpanded] : []);
  const { children: componentChildren } = children({
    active,
  }).props;
  const childCollection = useMemo(() => (Array.isArray(componentChildren) ? componentChildren : [componentChildren]), [
    componentChildren,
  ]);
  useEffect(() => {
    if (hasDefaultValue && childCollection.length - 1 < defaultExpanded) {
      // eslint-disable-next-line no-console
      console.error(`"defaultExpanded" property doesn't be more than length of children`);
    }
  }, [defaultExpanded, hasDefaultValue, childCollection]);

  return (
    <Wrapper as={component} className={className}>
      {childCollection.map((child, index) => {
        const { onExpanded = Function.prototype, onCollapsed = Function.prototype } = child.props;

        return cloneElement(child, {
          key: index,
          expanded: active.includes(index),
          onExpandStart: () => {
            setActive(controlled ? [index] : [...active, index]);
            onExpanded();
          },
          onCollapseStart: () => {
            setActive(active.filter((item) => item !== index));
            onCollapsed();
          },
        });
      })}
    </Wrapper>
  );
};

export const AccordionsGroup = memo<AccordionsGroupProps>(AccordionsGroupComponent);
