import { FC } from 'react';
import { NavLink, NavLinkProps, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Icon } from '@components/Icon';
import { ButtonSize, ButtonVariant, LinkProps, StyledButtonProps } from './types';
import { generateButtonStyled } from './utils';
import { IconWrapper } from './IconWrapper';

const StyledLink = styled(NavLink)<{ $buttonProps: StyledButtonProps } & NavLinkProps>`
  ${({ theme, $buttonProps }): string => generateButtonStyled($buttonProps, theme)}
`;

export const LinkRouter: FC<LinkProps & NavLinkProps & { activeProps?: LinkProps }> = ({
  to,
  themeColor = 'malachite',
  size = ButtonSize.Medium,
  fullWidth = false,
  variant = ButtonVariant.Filled,
  invertedTextColor = false,
  noWrap = false,
  className,
  startIcon,
  endIcon,
  children,
  href,
  activeProps,
  title,
}) => {
  const iconSize = size === ButtonSize.Small ? 12 : 20;
  const StartIcon = startIcon && (
    <IconWrapper isStart>
      <Icon name={startIcon} size={iconSize} />
    </IconWrapper>
  );

  const EndIcon = endIcon && (
    <IconWrapper>
      <Icon name={endIcon} size={iconSize} />
    </IconWrapper>
  );

  let buttonProps = {
    themeColor,
    size,
    href,
    fullWidth,
    variant,
    invertedTextColor,
    noWrap,
    iconName: null,
  };

  const { pathname } = useLocation();

  if (pathname === to) {
    buttonProps = {
      ...buttonProps,
      ...activeProps,
    };
  }

  return (
    <StyledLink to={to} $buttonProps={buttonProps} className={className} title={title}>
      {StartIcon}
      {children}
      {EndIcon}
    </StyledLink>
  );
};
