import { ThemeType, theme as appTheme } from '@styles/theme';

export const resetButtonStyle = `
  padding: 0;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

export const addHoverOpacity = `
  &:hover {
    opacity: 0.75;
  }
`;

export const customScroll = `
  @-moz-document url-prefix() {
    & {
      scrollbar-color: ${appTheme.colors.darkGray} transparent;
      scrollbar-width: thin;
    }
  }

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: ${appTheme.colors.darkGray};
    border-radius: 6px;
  }
`;

type Tags = 'input' | 'button';

export const generateFocusAndActiveStyles = (theme: ThemeType, tag: Tags, hasFocusVisible?: boolean): string => {
  let result = `
    &:before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      content: '';
      opacity: 0;
      transition: opacity 0.2s;
    }
    `;

  if (tag === 'button') {
    result += `
      &:active {
        &:before {
          background-color: rgba(0,0,0, 0.1);
          opacity: 1;
        }
      }

      &:focus {
        outline: none;
      }

      &:hover {
        &:before {
          background-color: rgba(255,236,174, 0.2);
          opacity: 1;
        }
      }

      &:focus-visible {
        &:before {
          top: -4px;
          right: -4px;
          bottom: -4px;
          left: -4px;
          opacity: 1;
          border: 1px dashed ${theme.colors.yellow};
        }
      }
      `;
  }

  if (tag === 'input') {
    result += `
      &:before {
        top: -4px;
        right: -4px;
        bottom: -4px;
        left: -4px;
        opacity: 1;
        border: 1px dashed ${theme.colors.yellow};
        content: ${hasFocusVisible === false ? 'none' : ''};
      }

      &:hover:not(:focus-within) {
        &:before {
          background-color: rgba(255,236,174, 0.2);
          opacity: 1;
        }
      }

      & input:focus {
        outline: none;
      }

      & textarea:focus {
        outline: none;
      }
    `;
  }

  return result;
};
