import { Breakpoints, ContainerWidth } from './types';

export const breakpoints: Breakpoints = {
  xl: 1639,
  lg: 1239,
  xmd: 767,
  md: 619,
  sm: 469,
  xs: 320,
};

export const containersWidth: ContainerWidth = {
  xl: 1560,
  lg: 800,
  md: 720,
  sm: 610,
  xs: 280,
};

export const theme = {
  spacing: 4,
  colors: {
    black: '#000',
    graphite: '#131b2e',
    white: '#fff',
    gray: '#e9ecf5',
    lightGray: '#fafafa',
    darkGray: '#9da2bf',
    terracotta: '#b73001',
    lightTerracotta: '#f3ddd5',
    malachite: '#075e3b',
    lightMalachite: '#d9efe6',
    yellow: '#f7ab0a',
    lightYellow: '#ffecae',
  },
  fonts: {
    sizes: {
      lg: '32px',
      md: '16px',
      sm: '12px',
    },
    weight: {
      regular: 400,
      medium: 500,
    },
  },
  borderRadius: {
    xs: '4px',
    md: '8px',
    lg: '12px',
  },
  zIndex: {
    hidden: -1,
    tooltip: 2,
    modal: 3,
    toast: 4,
  },
  breakpoints,
  containersWidth,
};

const { fonts, colors, borderRadius } = theme;

export type ColorsTheme = keyof typeof colors;
export type FontSizesTheme = keyof typeof fonts.sizes;
export type BorderRadiusTheme = keyof typeof borderRadius;
export type ThemeType = typeof theme;
