export const PHONE_FORMATED = '+7 705 710 33 77 ';
export const PHONE = PHONE_FORMATED.split(' ').join('');
export const EMAIL = 'Info@fabricaloft.kz';
export const REQUEST_APPLICATION_LINK = process.env.ORDER_BASE_URL || '/order';
export const CREATE_PROJECT_LINK = process.env.PRIVATE_OFFICE_URL || '/private-office';

export const WORKS_EXAMPLE_LINK = '#';

export const PROJECT_NAME = 'Interium';

export const INSTAGRAM_LINK = 'https://instagram.com/interium_online';
export const YOUTUBE_LINK = 'https://www.youtube.com/channel/UCiFugGn5_5jAe4bCS6sx8Dg/featured';
