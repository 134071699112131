import { ElementType, FC } from 'react';
import styled from 'styled-components';
import { Link } from 'react-scroll';
import { addHoverOpacity, resetButtonStyle } from '@styles/placeholders';
import { Icon } from '@components/Icon';
import { media } from '@styles/utils';

type TitleProps = {
  to: string;
  component?: ElementType;
};

const Wrapper = styled.div`
  margin: 120px 0;
  text-align: center;

  ${media.md`
     margin: 50px 0;
  `}
`;

const Button = styled.button`
  ${resetButtonStyle};
  text-align: center;
  color: ${({ theme }): string => theme.colors.darkGray};
  ${addHoverOpacity};
`;

const Text = styled.h2`
  margin-top: 0;
  margin-bottom: 8px;
  font-size: ${({ theme }): string => theme.fonts.sizes.md};
  font-weight: normal;
`;

export const NextBlockLink: FC<TitleProps> = ({ to, children, component }) => (
  <Wrapper>
    <Link to={to} offset={-90} spy hashSpy smooth>
      <Button>
        <Text as={component}>{children}</Text>
        <Icon name="arrow-down-full" size={20} />
      </Button>
    </Link>
  </Wrapper>
);
