import { FC } from 'react';
import styled from 'styled-components';
import { Icon } from '@components/Icon';
import { Loader } from '@components/Loader';
import { ButtonSize, ButtonVariant, IconButtonProps, IconLinkProps, StyledLinkProps } from './types';
import { generateButtonStyled } from './utils';

const StyledLink = styled.a<StyledLinkProps & Pick<IconButtonProps, 'iconColor'>>`
  ${({ theme, ...props }): string => generateButtonStyled(props, theme)}
`;

export const IconLink: FC<IconLinkProps> = ({
  themeColor,
  size = ButtonSize.Medium,
  fullWidth = false,
  variant = ButtonVariant.Filled,
  invertedTextColor = false,
  pending = false,
  iconColor,
  className,
  iconName,
  href,
}) => {
  const iconSize = size === ButtonSize.Small ? 12 : 20;

  return (
    <StyledLink
      href={href}
      themeColor={themeColor}
      size={size}
      fullWidth={fullWidth}
      variant={variant}
      invertedTextColor={invertedTextColor}
      className={className}
      iconName={iconName}
      iconColor={iconColor}
      pending={pending}
    >
      {pending ? <Loader size={iconSize} /> : <Icon name={iconName} size={iconSize} />}
    </StyledLink>
  );
};
