import { FC } from 'react';
import styled from 'styled-components';
import { media } from '@styles/utils';
import { Box } from '@components/Box';
import { Grid } from '@components/Grid';
import { Typography, TypographyVariants } from '@components/Typography';
import { BodyProps } from '../../types';

const StyledTitle = styled(Typography)`
  ${media.md`
    font-size: 24px;
  `}

  ${media.sm`
    font-size: 18px;
  `}
`;

const StyledPrice = styled(Typography).attrs({
  variant: TypographyVariants.Caption,
  withoutMargin: true,
  component: 'div',
})`
  margin-left: auto;
  padding: 3px 8px;
  border-radius: 4px;
  background: ${({ theme }): string => theme.colors.terracotta};
  color: ${({ theme }): string => theme.colors.white};
`;

export const Body: FC<BodyProps> = ({ stepText, primaryText, secondaryText, price, image }) => (
  <Box pt={5} px={10} sm={{ px: 5, pt: 0 }}>
    <Box display="flex" justify="center">
      <img src={image} alt={primaryText} />
    </Box>
    <Grid columns={2} md={{ pb: 2 }}>
      <Typography variant={TypographyVariants.Body} withoutMargin component="div">
        {stepText}
      </Typography>
      {price && <StyledPrice>{price}</StyledPrice>}
    </Grid>
    <StyledTitle variant={TypographyVariants.Title} withoutMargin component="p">
      {primaryText}
    </StyledTitle>
    <Typography variant={TypographyVariants.Caption} themeColor="darkGray" component="p">
      {secondaryText}
    </Typography>
  </Box>
);
