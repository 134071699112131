import { BodyProps } from './types';

export const DesignHowItWorks: Array<BodyProps> = [
  {
    stepText: 'Шаг 1',
    primaryText: 'Загрузить план объекта',
    secondaryText: 'Прикрепите план вашей квартиры или этажа',
    image: '/img/how_it_works/how-it-works-1-1.svg',
  },
  {
    stepText: 'Шаг 2',
    primaryText: 'Указать технические параметры',
    secondaryText: 'Оставьте свои пожелания по зонированию или планировкам комнат',
    image: '/img/how_it_works/how-it-works-2-1.svg',
  },
  {
    stepText: 'Шаг 3',
    primaryText: 'Выбрать стиль интерьера',
    secondaryText: 'Вы можете указать дизайнеру свои пожелания по цветам и стилю интерьера',
    image: '/img/how_it_works/how-it-works-3-1.svg',
  },
  {
    stepText: 'Шаг 4',
    primaryText: 'Получить дизайн проект от профессионала',
    secondaryText: 'Наши дизайнеры подготовят для вас полный пакет материлов к ремонту',
    image: '/img/how_it_works/how-it-works-4-1.svg', // price - add price label to slider
  },
];

export const ManualHowItWorks: Array<BodyProps> = [
  {
    stepText: 'Шаг 1',
    primaryText: 'Загрузить план объекта и выбрать стиль интерьера',
    secondaryText: 'Прикрепите план вашей квартиры или нарисуйте планировку с нуля',
    image: '/img/how_it_works/how-it-works-1-2.svg',
  },
  {
    stepText: 'Шаг 2',
    primaryText: 'Нарисовать планировку объекта в конструкторе',
    secondaryText: 'Разместите мебель, бытовую технику, cантехнику и декор',
    image: '/img/how_it_works/how-it-works-2-2.svg',
  },
  {
    stepText: 'Шаг 3',
    primaryText: 'Получить смету и дизайн коллажи',
    secondaryText: 'Полная детализация проекта',
    image: '/img/how_it_works/how-it-works-3-2.svg',
  },
  {
    stepText: 'Шаг 4',
    primaryText: 'Получить дизайн проект от умного конструктора',
    secondaryText: 'Скачайте пакет материалов для начала ремонта',
    image: '/img/how_it_works/how-it-works-4-2.svg',
  },
];
