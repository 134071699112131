import { useWindowSize } from 'react-use';
import { theme } from '@styles/theme';

type UseScreenSizeReturnedValue = {
  xl: boolean;
  lg: boolean;
  md: boolean;
  sm: boolean;
  xs: boolean;
  xmd: boolean;
};

export const useScreenSize = (): UseScreenSizeReturnedValue => {
  const { width } = useWindowSize();
  const { breakpoints } = theme;
  const isXl = width > breakpoints.lg;
  const isLg = width <= breakpoints.lg && width >= breakpoints.xmd;
  const isXMd = width <= breakpoints.xmd && width >= breakpoints.md;
  const isMd = width <= breakpoints.md && width >= breakpoints.sm;
  const isSm = width <= breakpoints.sm && width >= breakpoints.xs;
  const isXs = width <= breakpoints.xs;

  return { xl: isXl, lg: isLg, md: isMd, sm: isSm, xs: isXs, xmd: isXMd };
};
