import { ElementType } from 'react';
import * as CSS from 'csstype';
import { BreakpointsCssProperties, SpacingProps } from '@styles/types';
import { ColorsTheme } from '@styles/theme';

export enum TypographyVariants {
  Title = 'Title',
  Body = 'Body',
  BodyMedium = 'BodyMedium',
  Caption = 'Caption',
  CaptionMedium = 'CaptionMedium',
}

type AdditionalProps = {
  variant?: TypographyVariants;
  component?: ElementType;
  className?: string;
  align?: CSS.Property.TextAlign;
  themeColor?: ColorsTheme;
  display?: CSS.Property.Display;
  withoutMargin?: boolean;
  uppercase?: boolean;
  noWrap?: boolean;
  ellipsis?: boolean;
  mw?: CSS.Property.MaxWidth;
  maxWidth?: CSS.Property.MaxWidth;
};

export type TypographyProps = SpacingProps & AdditionalProps & BreakpointsCssProperties;
