import { FC } from 'react';
import styled from 'styled-components';
import { Element } from 'react-scroll';
import { Grid } from '@components/Grid';
import { Icon } from '@components/Icon';
import { Typography, TypographyVariants } from '@components/Typography';
import { Box } from '@components/Box';
import { media } from '@styles/utils';
import { EMAIL, PHONE, PHONE_FORMATED, INSTAGRAM_LINK, YOUTUBE_LINK } from '../constants';
import { NextBlockLink } from '../../../common/components/NextBlockLink';

const SocialIcon = styled(Icon)`
  width: 20px;
  height: 20px;
  fill: ${({ theme }): string => theme.colors.black};
  margin-right: 20px;
`;

const HeaderText = styled(Typography).attrs({
  component: 'h2',
  variant: TypographyVariants.Title,
})``;
const Text = styled(Typography).attrs({ component: 'div', variant: TypographyVariants.Body })``;
const LightText = styled(Typography).attrs({
  component: 'div',
  variant: TypographyVariants.CaptionMedium,
  themeColor: 'darkGray',
})`
  margin-top: 4px;
`;

const StyledImage = styled.img`
  position: relative;
  top: -40px;

  ${media.md`
     top: 0;
  `}

  ${media.sm`
     display: none;
  `}
`;

export const About: FC = () => {
  return (
    <section>
      <NextBlockLink to="about">О проекте и контакты</NextBlockLink>

      <Element name="about">
        <Box marginBottom={32} md={{ marginBottom: 10 }}>
          <Box marginTop={5}>
            <HeaderText>О проекте</HeaderText>
            <Grid
              templateColumns="auto 213px"
              gapX={100}
              lg={{ gapX: 50 }}
              md={{ templateColumns: 'auto 100px', gapX: 20 }}
              sm={{ templateColumns: '1fr' }}
            >
              <Text>
                Умный конструктор Interium помогает нарисовать квартиру мечты: от плана расстановки мебели до плана
                освещения и расстановки розеток. При этом пользователь может выгрузить смету на ремонтно-строительные
                работы по своему объекту и получить дизайн коллажи по желаемому стилю. Все это не требует технических
                навыков, а всего лишь желание нарисовать проект своей мечты! Альтернативно пользователям доступна
                функция «Закажи проект у дизайнера», где по заданным параметрам квартиры и тех плану, профессиональный
                дизайнер разработает планировочное решение и технические чертежи.
              </Text>
              <StyledImage src="/img/landing/about.svg" alt="About Image" />
            </Grid>
          </Box>
          <Box marginTop={5}>
            <HeaderText>Контакты</HeaderText>
            <div>
              <Grid columns={2} gapX={80} width="fit-content" md={{ columns: 1, gapY: 20 }}>
                <div>
                  <Text>
                    Телефон: <a href={`tel:${PHONE}`}>{PHONE_FORMATED}</a>
                  </Text>
                  <LightText>C 8:00 - 15:00 по МСК</LightText>
                </div>
                <div>
                  <Text>
                    Email:{' '}
                    <a href={`mailto:${EMAIL}`} target="_blank" rel="noreferrer">
                      {EMAIL}
                    </a>
                  </Text>
                  <LightText>24/7</LightText>
                </div>
              </Grid>
              <Box marginTop={5}>
                <a target="_blank" rel="noreferrer" href={INSTAGRAM_LINK}>
                  <SocialIcon name="soc-inst" />
                </a>
                <a target="_blank" rel="noreferrer" href={YOUTUBE_LINK}>
                  <SocialIcon name="soc-yt" />
                </a>
              </Box>
            </div>
          </Box>
        </Box>
      </Element>
    </section>
  );
};
