import { ElementType, FC } from 'react';
import styled, { keyframes } from 'styled-components';
import { ColorsTheme } from '@styles/theme';

export type LoaderProps = {
  themeColor?: ColorsTheme;
  size?: number;
  className?: string;
  component?: ElementType;
};

const rotate = keyframes`
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
`;

const CircularLoader = styled.div<LoaderProps>`
  width: ${({ size }): string => `${size}px`};
  height: ${({ size }): string => `${size}px`};
  color: inherit;
  border-color: transparent transparent transparent
    ${({ theme, themeColor }): string => (themeColor ? theme.colors[themeColor] : 'currentColor')};
  border-style: solid;
  border-width: 2px;
  border-radius: 100%;
  animation: ${rotate} 0.5s linear infinite;
`;

export const Loader: FC<LoaderProps> = ({ themeColor, size = 20, className }): JSX.Element => (
  <CircularLoader className={className} themeColor={themeColor} size={size} />
);
