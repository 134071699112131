import { FC, useState } from 'react';
import styled from 'styled-components';
import { Link as AnchorLink } from 'react-scroll';
import { Typography, TypographyVariants } from '@components/Typography';
import { Button, Link, ButtonSize, ButtonVariant } from '@components/Button';
import { Icon } from '@components/Icon';
import { media } from '@styles/utils';
import { CREATE_PROJECT_LINK } from '@landingSrc/features/MainPage/constants';
import logo from '@static/img/landing/logo.png';
import { Container } from '../components/Container';

const Wrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 2;
  padding: 20px 0;
  background: ${({ theme }): string => theme.colors.white};

  ${media.sm`
    padding: 10px 0;
  `}
`;

const Inner = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

const LogoWrapper = styled.div`
  width: 160px;
  height: 100%;
`;

const Menu = styled.div<{ isOpenedOnMobile: boolean }>`
  position: relative;
  margin-left: auto;
  margin-right: 40px;
  z-index: 3;

  ${media.lg`
    position: absolute;
    top: 0;
    left: 0;
    width: 320px;
    height: 100vh;
    margin: 0;
    padding: 40px 20px 40px 36px;
    background: ${({ theme }): string => theme.colors.white};
    transform: translateX(${({ isOpenedOnMobile }): string => (isOpenedOnMobile ? '0' : '-100%')});
    transition: transform 0.5s
  `}
`;

const List = styled.ul`
  position: relative;
  top: 5px;
  margin: 0;
  padding: 0;
  list-style: none;
`;

const Item = styled.li`
  display: inline-block;
  vertical-align: bottom;
  margin: 0 0 0 32px;
  padding: 0;

  ${media.lg`
    display: block;
    margin: 0 0 10px 0;
  `}
`;

const BurgerButton = styled.button`
  display: none;
  margin-right: 15px;
  padding: 5px;
  background: transparent;
  border: none;
  color: ${({ theme }): string => theme.colors.black};

  ${media.lg`
    display: block;
  `}
`;

const StyledMenuButton = styled(Button)`
  line-height: 1;

  && {
    &::before,
    &:active::before,
    &:focus::before {
      border: none;
      background-color: transparent;
      opacity: 1;
    }
  }

  .active > & {
    color: ${({ theme }): string => theme.colors.malachite};

    @media (min-width: ${({ theme }): number => theme.breakpoints.lg + 1}px) {
      &::before,
      &:focus::before {
        content: '';
        position: absolute;
        top: -4px;
        left: 50%;
        transform: translateX(-50%);
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 5px 0 5px;
        border-color: ${({ theme }): string => theme.colors.malachite} transparent transparent transparent;
      }
    }
  }
`;

const CloseButton = styled.button`
  display: none;
  position: absolute;
  top: 20px;
  right: 30px;
  padding: 5px;
  background: transparent;
  border: none;
  color: ${({ theme }): string => theme.colors.black};

  ${media.lg`
    display: block;
  `}
`;

const StyledText = styled(Typography)`
  font-size: 14px;
`;

const HeaderButton: FC<{ to: string; offset?: number; closeMenu: () => void }> = ({
  to,
  offset = -90,
  closeMenu,
  children,
}) => {
  return (
    <AnchorLink to={to} spy hashSpy smooth offset={offset} onSetActive={closeMenu} onSetInactive={closeMenu}>
      <StyledMenuButton
        themeColor="black"
        size={ButtonSize.Small}
        variant={ButtonVariant.Text}
        style={{ fontWeight: 'bold' }}
      >
        <StyledText component="div" variant={TypographyVariants.CaptionMedium}>
          {children}
        </StyledText>
      </StyledMenuButton>
    </AnchorLink>
  );
};

export const Header: FC = () => {
  const [isMenuOpened, setMenuState] = useState<boolean>(false);

  return (
    <Wrapper>
      <Container>
        <Inner>
          <BurgerButton onClick={(): void => setMenuState(true)}>
            <Icon name="burger" size={20} />
          </BurgerButton>
          <LogoWrapper>
            <img src={logo} alt="logo" />
          </LogoWrapper>
          <Menu isOpenedOnMobile={isMenuOpened}>
            <List>
              <Item>
                <HeaderButton to="services" closeMenu={(): void => setMenuState(false)}>
                  Услуги
                </HeaderButton>
              </Item>
              <Item>
                <HeaderButton to="howItWorks" closeMenu={(): void => setMenuState(false)}>
                  Как это работает
                </HeaderButton>
              </Item>
              <Item>
                <HeaderButton to="capabilities" offset={-200} closeMenu={(): void => setMenuState(false)}>
                  Что входит в дизайн-проект
                </HeaderButton>
              </Item>
              <Item>
                <HeaderButton to="faq" closeMenu={(): void => setMenuState(false)}>
                  Вопрос-Ответ
                </HeaderButton>
              </Item>
              <Item>
                <HeaderButton to="about" closeMenu={(): void => setMenuState(false)}>
                  О проекте
                </HeaderButton>
              </Item>
            </List>
            <CloseButton onClick={(): void => setMenuState(false)}>
              <Icon name="close" />
            </CloseButton>
          </Menu>

          <Link href={CREATE_PROJECT_LINK} themeColor="black" size={ButtonSize.Small} variant={ButtonVariant.Filled}>
            Войти
          </Link>
        </Inner>
      </Container>
    </Wrapper>
  );
};
