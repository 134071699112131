import { ButtonHTMLAttributes, DetailedHTMLProps, ElementType, FC, memo } from 'react';
import styled from 'styled-components';
import { opacify } from 'polished';
import { ColorsTheme, BorderRadiusTheme } from '@styles/theme';

export type PaperProps = {
  isSquare?: boolean;
  borderRadius?: BorderRadiusTheme;
  backgroundColor?: ColorsTheme;
  themeColor?: ColorsTheme;
  customPadding?: string;
  component?: ElementType;
  className?: string;
} & DetailedHTMLProps<ButtonHTMLAttributes<HTMLElement>, HTMLElement>;

const Wrapper = styled.div<PaperProps>`
  padding: ${({ customPadding }): string => (typeof customPadding !== 'undefined' ? customPadding : '20px')};
  background-color: ${({ theme, backgroundColor }): string | false => backgroundColor && theme.colors[backgroundColor]};
  border-radius: ${({ theme, borderRadius, isSquare }): string | false =>
    borderRadius && !isSquare && theme.borderRadius[borderRadius]};
  border: none;
  color: ${({ theme, themeColor }): string | false => themeColor && theme.colors[themeColor]};
  box-shadow: ${({ theme, backgroundColor }): string =>
    `8px 8px 20px 0 ${
      backgroundColor === 'white' ? opacify(-0.85, theme.colors.darkGray) : opacify(-0.8, theme.colors[backgroundColor])
    }`};
`;

export const PaperComponent: FC<PaperProps> = (props): JSX.Element => {
  const {
    children,
    component,
    themeColor,
    customPadding,
    isSquare = false,
    borderRadius = 'md',
    backgroundColor = 'white',
    className,
    ...other
  } = props;

  return (
    <Wrapper
      as={component}
      className={className}
      isSquare={isSquare}
      borderRadius={borderRadius}
      backgroundColor={backgroundColor}
      themeColor={themeColor}
      customPadding={customPadding}
      {...other}
    >
      {children}
    </Wrapper>
  );
};

export const Paper = memo<FC<PaperProps>>(PaperComponent);
