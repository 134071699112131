import { FC } from 'react';
import { Element } from 'react-scroll';
import { Grid } from '@components/Grid';
import { NextBlockLink } from '../../../../common/components/NextBlockLink';
import { REQUEST_APPLICATION_LINK, CREATE_PROJECT_LINK, WORKS_EXAMPLE_LINK } from '../../constants';
import { DesignHowItWorks, ManualHowItWorks } from './data';
import { Block } from './Block';

export const HowItWorks: FC = () => {
  return (
    <section>
      <NextBlockLink to="howItWorks">Как это работает</NextBlockLink>

      <Element name="howItWorks">
        <Grid templateColumns="repeat(2,50%)" lg={{ templateColumns: 'repeat(1,100%)', gapY: 20 }} gapX={20}>
          <Block
            type="Design"
            title="Доверить проект дизайнеру"
            actionLink={REQUEST_APPLICATION_LINK}
            titleIcon="request_40x40"
            exampleUrl={WORKS_EXAMPLE_LINK}
            body={DesignHowItWorks}
          />
          <Block
            type="Manual"
            title="Создать интерьер самому"
            actionLink={CREATE_PROJECT_LINK}
            titleIcon="pen_40x40"
            exampleUrl={WORKS_EXAMPLE_LINK}
            body={ManualHowItWorks}
          />
        </Grid>
      </Element>
    </section>
  );
};
