export const CSS_PROPERTIES_ALIASES = {
  p: 'padding',
  pt: 'paddingTop',
  pr: 'paddingRight',
  pb: 'paddingBottom',
  pl: 'paddingLeft',
  px: 'paddingX',
  py: 'paddingY',
  m: 'margin',
  mt: 'marginTop',
  mr: 'marginRight',
  mb: 'marginBottom',
  ml: 'marginLeft',
  mx: 'marginX',
  my: 'marginY',
  bg: 'backgroundColor',
  mw: 'maxWidth',
  mh: 'maxHeight',
  w: 'width',
  h: 'height',
};
