import { FC } from 'react';
import styled from 'styled-components';
import ReactDOMServer from 'react-dom/server';
import SwiperCore, { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import { Paper } from '@components/Paper';
import { Box } from '@components/Box';
import { Link } from '@components/Button';
import { media } from '@styles/utils';
import { resetButtonStyle } from '@styles/placeholders';
import { Header } from './components/Header';
import { Body } from './components/Body';
import { BlockProps } from './types';

const Wrapper = styled(Box)`
  min-width: 0;
  overflow: hidden;
`;

SwiperCore.use([Pagination, Autoplay]);

const StyledButton = styled.button`
  ${resetButtonStyle};
  width: 40px;
  height: 40px;
  padding: 0;
  justify-content: center;
  margin-right: 8px;
  background: white;
  color: black;
  box-shadow: rgb(0 0 0 / 20%) 8px 8px 20px 0px;
  border-radius: 8px;

  &.swiper-pagination-bullet-active {
    background: black;
    color: white;
  }

  ${media.md`
    width: 8px;
    height: 8px;
    font-size: 0;
  `};
`;

export const Block: FC<BlockProps> = ({ type, title, titleIcon, exampleUrl, body, actionLink }) => {
  const isDesign = type === 'Design';

  return (
    <Paper backgroundColor={type === 'Design' ? 'gray' : 'lightMalachite'} customPadding="0px">
      <Wrapper display="flex" direction="column" height="100%">
        <Header type={type} title={title} titleIcon={titleIcon} exampleUrl={exampleUrl} />
        <Box width="100%" overflow="hidden">
          <Swiper
            pagination={{
              clickable: true,
              el: '.swiper-pagination',
              renderBullet: (index, className): string => {
                return ReactDOMServer.renderToString(<StyledButton className={className}>{index + 1}</StyledButton>);
              },
            }}
            loop
            autoplay={{
              delay: 3000,
            }}
            slidesPerView={1}
          >
            {body.map((item, currentIndex) => (
              <SwiperSlide key={currentIndex}>
                <Body {...item} />
              </SwiperSlide>
            ))}
            <Box
              display="flex"
              justify="space-between"
              mt="auto"
              px={10}
              pb={10}
              pt={5}
              sm={{ direction: 'column', px: 5, pt: 0, pb: 5 }}
            >
              <Box display="flex" mx={-2} sm={{ py: 5 }}>
                <div style={{ display: 'flex', alignItems: 'center' }} className="swiper-pagination" />
              </Box>
              <Box mt="auto">
                <Link
                  noWrap
                  href={actionLink}
                  themeColor={isDesign ? 'black' : 'yellow'}
                  invertedTextColor={type === 'Manual'}
                >
                  {isDesign ? 'Оставить заявку' : 'Создать проект'}
                </Link>
              </Box>
            </Box>
          </Swiper>
        </Box>
      </Wrapper>
    </Paper>
  );
};
