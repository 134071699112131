import styled from 'styled-components';
import { PendingPosition } from '@components/Button/types';

type LoaderWrapper = {
  position?: PendingPosition;
};

export const LoaderWrapper = styled.div<LoaderWrapper>`
  ${({ position }): string => position && position === PendingPosition.Start && `padding-right: 10px;`};
  ${({ position }): string => position && position === PendingPosition.End && `padding-left: 10px;`};
  ${({ position }): string => position && position === PendingPosition.Center && `padding: 0 10px;`};
`;
