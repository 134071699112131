import { FC } from 'react';
import styled from 'styled-components';

type SvgProps = {
  size?: number;
  iconColor?: string;
  className?: string;
};

export type IconProps = SvgProps & {
  name: string;
};

const Svg = styled.svg<SvgProps>`
  display: inline-block;
  width: ${({ size }): number => size || 20}px;
  height: ${({ size }): number => size || 20}px;
  vertical-align: middle;
  fill: ${({ iconColor }): string => iconColor || 'currentColor'};
  flex-shrink: 0;
`;

export const Icon: FC<IconProps> = ({ name, size, iconColor, className }): JSX.Element => {
  if (name === 'color') {
    return (
      <Svg
        as="div"
        className={className}
        size={size}
        style={{
          borderRadius: '50%',
          background: 'conic-gradient(#00ff2c, #fff400, #ff0500, #ff00ce, #0026ff, #00f6ff, #00ff2c)',
        }}
      />
    );
  }

  return (
    <Svg className={className} size={size} iconColor={iconColor}>
      <use xlinkHref={`#${name}`} />
    </Svg>
  );
};
