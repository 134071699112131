import { css, FlattenSimpleInterpolation } from 'styled-components';
import { theme } from './theme';
import { CSS_PROPERTIES_ALIASES } from './constants';
import { Interpolation, Breakpoints } from './types';

const initBreakpointAcc: Interpolation<Breakpoints> = {
  xl: () => ``,
  lg: () => ``,
  xmd: () => ``,
  md: () => ``,
  sm: () => ``,
  xs: () => ``,
};

export const media = Object.keys(theme.breakpoints).reduce<Interpolation<Breakpoints>>((acc, label) => {
  acc[label] = (first, ...interpolations): FlattenSimpleInterpolation => css`
    @media (max-width: ${theme.breakpoints[label]}px) {
      ${css(first, ...interpolations)}
    }
  `;

  return acc;
}, initBreakpointAcc);

export const getSpacingValue = (unit: number | string, defaultValue = theme.spacing || 4): string =>
  typeof unit === 'string' ? unit : `${unit * defaultValue}px`;

export const replacePropsAliases = (props: { [key: string]: any }): { [key: string]: any } =>
  Object.keys(props).reduce(
    (acc, currentElement) =>
      CSS_PROPERTIES_ALIASES[currentElement]
        ? { ...acc, [CSS_PROPERTIES_ALIASES[currentElement]]: props[currentElement] }
        : { ...acc, [currentElement]: props[currentElement] },
    {},
  );

export const buildStyles = (props: { [key: string]: any }): string => {
  const options = {
    position: (value): string => `position: ${value};`,
    top: (value): string => `top: ${value};`,
    right: (value): string => `right: ${value};`,
    bottom: (value): string => `bottom: ${value};`,
    left: (value): string => `left: ${value};`,
    overflow: (value): string => `overflow: ${value};`,
    overflowX: (value): string => `overflow-x: ${value};`,
    overflowY: (value): string => `overflow-y: ${value};`,
    display: (value): string => `display: ${value};`,
    direction: (value): string => `flex-direction: ${value};`,
    flexWrap: (value): string => `flex-wrap: ${value};`,
    justify: (value): string => `justify-content: ${value};`,
    alignItems: (value): string => `align-items: ${value};`,
    alignContent: (value): string => `align-content: ${value};`,
    order: (value): string => `order: ${value};`,
    flex: (value): string => `flex: ${value};`,
    flexBasis: (value): string => `flex-basis: ${value};`,
    flexGrow: (value): string => `flex-grow: ${value};`,
    flexShrink: (value): string => `flex-shrink: ${value};`,
    flexDirection: (value): string => `flex-direction: ${value}`,
    alignSelf: (value): string => `align-self: ${value};`,
    width: (value): string => `width: ${value};`,
    height: (value): string => `height: ${value};`,
    maxWidth: (value): string => `max-width: ${value};`,
    maxHeight: (value): string => `max-height: ${value};`,
    boxSizing: (value): string => `box-sizing: ${value};`,
    margin: (value): string => `margin: ${getSpacingValue(value)};`,
    marginTop: (value): string => `margin-top: ${getSpacingValue(value)};`,
    marginRight: (value): string => `margin-right: ${getSpacingValue(value)};`,
    marginBottom: (value): string => `margin-bottom: ${getSpacingValue(value)};`,
    marginLeft: (value): string => `margin-left: ${getSpacingValue(value)};`,
    marginX: (value): string => `
      margin-right: ${getSpacingValue(value)};
      margin-left: ${getSpacingValue(value)};
    `,
    marginY: (value): string => `
      margin-top: ${getSpacingValue(value)};
      margin-bottom: ${getSpacingValue(value)};
    `,
    padding: (value): string => `padding: ${getSpacingValue(value)};`,
    paddingTop: (value): string => `padding-top: ${getSpacingValue(value)};`,
    paddingRight: (value): string => `padding-right: ${getSpacingValue(value)};`,
    paddingBottom: (value): string => `padding-bottom: ${getSpacingValue(value)};`,
    paddingLeft: (value): string => `padding-left: ${getSpacingValue(value)};`,
    paddingX: (value): string => `
      padding-right: ${getSpacingValue(value)};
      padding-left: ${getSpacingValue(value)};
    `,
    paddingY: (value): string => `
      padding-top: ${getSpacingValue(value)};
      padding-bottom: ${getSpacingValue(value)};
    `,
    backgroundColor: (value): string => `background-color: ${theme.colors[value]};`,
    borderRadius: (value): string => `border-radius: ${value};`,
    align: (value): string => `text-align: ${value};`,
    zIndex: (value): string => `z-index: ${value};`,
  };

  return Object.keys(props).reduce(
    (acc, currentValue): string =>
      options[currentValue] ? `${acc}${options[currentValue](props[currentValue])}` : acc,
    ``,
  );
};

export const getAdaptiveStyles = (props: { [key: string]: any }): string => {
  const breakPoints = Object.keys(props).reduce(
    (acc, currentElement) =>
      Object.keys(theme.breakpoints).includes(currentElement)
        ? { ...acc, [currentElement]: replacePropsAliases(props[currentElement]) }
        : acc,
    {},
  );

  return Object.keys(breakPoints).reduce((acc, currentBreakpoint) => {
    return `${acc}
      @media (max-width: ${theme.breakpoints[currentBreakpoint]}px) {
        ${buildStyles(breakPoints[currentBreakpoint])}
      }
    `;
  }, ``);
};

export const getStyles = (props: { [key: string]: any }): string => {
  const desktopStyles = buildStyles(props);
  const adaptiveStyles = getAdaptiveStyles(props);

  return `${desktopStyles};${adaptiveStyles}`;
};

export const sortCssBreakpointsProperties = (props: { [key: string]: any }): { [key: string]: any } => {
  const breakpoints = Object.keys(initBreakpointAcc);
  const sortByBreakpointIndex = (a, b): number => breakpoints.indexOf(a) - breakpoints.indexOf(b);

  return Object.keys(props)
    .sort(sortByBreakpointIndex)
    .reduce((acc, currentElement) => ({ ...acc, [currentElement]: props[currentElement] }), {});
};
