import { ThemeType } from '@styles/theme';
import { rgba } from 'polished';
import { generateFocusAndActiveStyles } from '@styles/placeholders';
import { ButtonSize, ButtonVariant, IconWrapperProps, StyledButtonProps, StyledLinkProps } from './types';

export const generateButtonStyled = (
  {
    size,
    variant,
    themeColor,
    invertedTextColor,
    withoutShadow,
    fullWidth,
    noWrap,
    iconName,
    pending,
  }: StyledButtonProps | StyledLinkProps,
  theme: ThemeType,
): string => {
  const isIconButton = !!iconName;

  let result = `
    ${fullWidth ? 'width: 100%; justify-content: center;' : ''}
    position: relative;
    display: inline-flex;
    align-items: center;
    border-width: 1px;
    border-style: solid;
    cursor: pointer;
    text-decoration: none;
    letter-spacing: 1px;
    ${noWrap ? 'white-space: nowrap;' : ''}

    ${generateFocusAndActiveStyles(theme, 'button')}

    &:disabled {
      pointer-events: none;
      box-shadow: none;
      border-color: ${theme.colors.gray};
    }

    &:hover {
      text-decoration: none;
    }
  `;

  switch (size) {
    case ButtonSize.Small:
      result += `
        height: 24px;
        padding: 0 ${isIconButton ? '5px' : '8px'};
        border-radius: 4px;
        font-size: 12px;
        font-weight: 400;
      `;
      break;
    case ButtonSize.Medium:
      result += `
        height: 40px;
        padding: 0 ${isIconButton ? '9px' : `${pending ? '25px' : '40px'}`};
        border-radius: 8px;
        font-size: 16px;
        font-weight: 500;
      `;
      break;
    default:
      break;
  }

  switch (variant) {
    case ButtonVariant.Filled:
      result += `
        background: ${theme.colors[themeColor]};
        border-color: ${theme.colors[themeColor]};
        color: ${invertedTextColor ? theme.colors.black : theme.colors.white};
        ${!withoutShadow ? `box-shadow: 8px 8px 20px 0 ${rgba(theme.colors[themeColor], 0.2)}` : ''};

        &:disabled {
          color: ${invertedTextColor ? theme.colors.graphite : theme.colors.white};
          background: ${theme.colors.gray};
        }
      `;
      break;
    case ButtonVariant.Contained:
      result += `
        background: transparent;
        border-color: ${theme.colors[themeColor]};
        color: ${invertedTextColor ? theme.colors.white : theme.colors[themeColor]};

        &:disabled {
          color: ${invertedTextColor ? theme.colors.gray : theme.colors.white};
          background: ${theme.colors.gray};
        }
      `;
      break;
    case ButtonVariant.Text:
      result += `
        height: 28px;
        padding: 4px;
        line-height: 20px;
        background: transparent;
        border: none;
        color: ${theme.colors[themeColor]};
        font-weight: 400;

        &:disabled {
          color: ${theme.colors.gray};
        }
      `;
      break;
    default:
      break;
  }

  return result;
};

export const generateIconStyle = (
  { variant, size, isStart, noDividerLine = false }: IconWrapperProps,
  theme: ThemeType,
): string => {
  let result = 'display: block;';
  result += isStart ? 'padding-right: 8px;' : 'padding-left: 8px';

  if (size === ButtonSize.Small && !noDividerLine) {
    result += isStart
      ? `
      border-right: 1px solid ${rgba(theme.colors.darkGray, 0.2)};
      margin-right: 8px;
    `
      : `
      border-left: 1px solid ${rgba(theme.colors.darkGray, 0.2)};
      margin-left: 8px;
    `;
  }

  if (variant === ButtonVariant.Text) {
    result += isStart ? 'padding-right: 10px;' : 'padding-left: 10px';
  }

  return result;
};
