import { FC } from 'react';
import styled from 'styled-components';
import { Element } from 'react-scroll';
import { Typography, TypographyVariants } from '@components/Typography';
import { Box } from '@components/Box';
import { Accordion, AccordionsGroup } from '@components/Accordion';
import { Container } from '@components/Container';
import { NextBlockLink } from '@landingSrc/common/components/NextBlockLink';
import { PROJECT_NAME } from '@landingSrc/features/MainPage/constants';
import pdfExportDrawing from '@static/documents/export-drawings.pdf';

const questions = [
  {
    question: `Что можно получить с помощью конструктора ${PROJECT_NAME}?`,
    answer: (
      <p>
        Умный конструктор помогает подготовить базовые технические чертежи для дизайна интерьера на основе
        планировочного решения объекта. Планировочное решение можно нарисовать самостоятельно либо заказать у
        профессионала. Помимо этого, умный конструктор выдает смету на ремонтно-отделочные работы и дизайн коллажи на
        основе заданных параметров.
      </p>
    ),
  },
  {
    question: 'Чем отличается «Доверить проект дизайнеру» от «Создай интерьер сам»?',
    answer: (
      <>
        <p>
          В первом случае вы доверяете проект профессионалу, который нарисует планировочное решение на основе
          вашейзаявки. Вы сможете вносить корректировки в планировочное решение до двух раз, после чего дизайнер вам
          выдаст дизайн проект от {PROJECT_NAME} в формате .pdf к вам на почту.
        </p>
        <p>
          Во втором случае вы рисуете планировочное решение на конструкторе {PROJECT_NAME} самостоятельно, на основе
          которого можно загрузить дизайн проект с техническими чертежами и коллажами. В данном случае вы можете
          редактировать технические чертежи вручную, а затем экспортировать в формат .pdf.
        </p>
      </>
    ),
  },
  {
    question: 'Сколько стоит разработка дизайн проекта от профессионала?',
    answer: 'Стоимость разработки дизайн проекта будет отправлена Вам менеджером.',
  },
  {
    question:
      'Я хочу нарисовать планировку тех чертежи самостоятельно. Сколько стоит доступ к полной версии умного конструктора?',
    answer: `В самостоятельном режиме планировочное решение можно нарисовать бесплатно. Личный кабинет сохраняет до пяти проектов одновременно.`,
  },
  {
    question: 'Смогу ли я самостоятельно нарисовать планировку без технических навыков?',
    answer: 'Да, конструктор создан с удобным интерфейсом для самостоятельного создания дизайн проекта.',
  },
  {
    question: 'Что входит в технические чертежи и могу ли я их редактировать?',
    answer: (
      <p>
        В технические чертежи проекта входят: план стен и перегородок, план освещения, план потолков, план полов, план
        расстановки розеток. Их можно редактировать вручную. Пример технических чертежей можно{' '}
        <a target="_blank" rel="noreferrer" href={pdfExportDrawing} style={{ textDecorationLine: 'underline' }}>
          посмотреть здесь
        </a>
        .
      </p>
    ),
  },
  {
    question: 'Чем отличаются коллажи от 3D-визуализации?',
    answer:
      'Дизайн коллажи – это комбинация элементов мебели и декора в виде фото нарезок, которые отражают основную идею стиля интерьера. В отличие от 3D-визуализации коллажи не адаптируются подпланировочное решение каждого объекта, а помогают пользователю с выбором определенных элементов интерьера и отделочных материалов при ремонте.',
  },
  {
    question: `Насколько точна смета ${PROJECT_NAME}?`,
    answer:
      'Смета на ремонтно-отделочные работы является ориентировочной и основывается на заданных параметрах в конструкторе. Смета не включает отдельные элементы или декор по стенам, но дает максимально реалистичнуюоценку по остальным видам работ.',
  },
];

const Question = styled.div<any>`
  position: relative;
  padding: 20px 60px 20px 0;
  border-top: 1px solid ${({ theme }): string => theme.colors.gray};

  &::before,
  &::after {
    position: absolute;
    color: inherit;
    background-color: currentColor;
    content: '';
  }

  &:before {
    top: 30px;
    right: 0;
    width: 18px;
    height: 1px;
  }

  &:after {
    top: 21px;
    right: 9px;
    width: 1px;
    height: 18px;
  }

  ${({ isActive }): string | false =>
    isActive &&
    `
    &:after {
      content: none;
    }
  `}

  &:hover {
    color: ${({ theme }): string => theme.colors.malachite};
  }
`;

export const FAQ: FC = () => (
  <section>
    <NextBlockLink to="faq">Вопрос-Ответ</NextBlockLink>

    <Element name="faq">
      <Container maxWidth="lg" disableGutters>
        <Box mb={10}>
          <Typography variant={TypographyVariants.Title} component="h3" withoutMargin>
            Популярные вопросы
          </Typography>
        </Box>
        <AccordionsGroup>
          {({ active }): JSX.Element => (
            <>
              {questions.map(({ question, answer }, index) => (
                <Accordion
                  pane={
                    <Question isActive={active.includes(index)}>
                      <Typography variant={TypographyVariants.BodyMedium} component="div">
                        {question}
                      </Typography>
                    </Question>
                  }
                  key={question}
                >
                  <Typography variant={TypographyVariants.Body} component="div">
                    {answer}
                  </Typography>
                </Accordion>
              ))}
            </>
          )}
        </AccordionsGroup>
      </Container>
    </Element>
  </section>
);
