import { FC } from 'react';
import styled from 'styled-components';
import { Typography, TypographyVariants } from '@components/Typography';
import { Grid } from '@components/Grid';
import { Box } from '@components/Box';
import { Icon } from '@components/Icon';
import { media } from '@styles/utils';
import pdf from '@static/documents/privacyPolicy.pdf';
import { Container } from '../components/Container';
import { EMAIL, PHONE, PHONE_FORMATED, INSTAGRAM_LINK, YOUTUBE_LINK, PROJECT_NAME } from '../constants';

const Wrapper = styled.div`
  padding: 40px 0;
  background: ${({ theme }): string => theme.colors.black};
  color: ${({ theme }): string => theme.colors.white};
`;

const SocialIcon = styled(Icon)`
  width: 20px;
  height: 20px;
  fill: ${({ theme }): string => theme.colors.white};
  margin-right: 20px;

  ${media.sm`
     margin-right: 12px;
  `}
`;

const HeaderText = styled(Typography).attrs({
  component: 'h4',
  variant: TypographyVariants.CaptionMedium,
  uppercase: true,
  withoutMargin: true,
})`
  &::before {
    content: '';
    display: block;
    width: 40px;
    height: 2px;
    margin: 0 0 8px;
    background-color: #fff;
  }
`;
const Text = styled(Typography).attrs({ component: 'div', variant: TypographyVariants.CaptionMedium })``;
const LightText = styled(Text)`
  opacity: 0.5;
`;

export const Footer: FC = () => {
  return (
    <Wrapper>
      <Container>
        <HeaderText>Контакты</HeaderText>
        <Box marginTop={5}>
          <Grid columns={2} gapX={40} md={{ columns: 1, gapY: 20 }}>
            <Grid columns={2} gapX={80} width="fit-content" lg={{ gapX: 20 }} md={{ columns: 1, gapY: 20 }}>
              <div>
                <Text>
                  Телефон: <a href={`tel:${PHONE}`}>{PHONE_FORMATED}</a>
                </Text>
                <Box marginTop={2}>
                  <LightText>C 8:00 - 15:00 по МСК</LightText>
                </Box>
              </div>
              <div>
                <Text>
                  Email:{' '}
                  <a href={`mailto:${EMAIL}`} target="_blank" rel="noreferrer">
                    {EMAIL}
                  </a>
                </Text>
                <Box marginTop={2}>
                  <LightText>24/7</LightText>
                </Box>
              </div>
            </Grid>
            <div>
              <Text>
                {/* eslint-disable-next-line */}
                <a target="_blank" rel="noreferrer" href={pdf} download="Политика конфиденциальности.pdf">
                  Политика конфиденциальности
                </a>
              </Text>
            </div>
          </Grid>
        </Box>
        <Box marginTop={5}>
          <Grid columns={2} gapX={40} md={{ gapX: '0' }}>
            <div>
              <a href={INSTAGRAM_LINK}>
                <SocialIcon name="soc-inst" />
              </a>
              <a href={YOUTUBE_LINK}>
                <SocialIcon name="soc-yt" />
              </a>
            </div>
            <LightText>© {PROJECT_NAME}, 2022</LightText>
          </Grid>
        </Box>
      </Container>
    </Wrapper>
  );
};
