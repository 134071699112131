import { theme } from '@styles/theme';

export const buildAdaptiveGridStyles = (props: { [key: string]: any }): string => {
  const options = {
    columns: (value): string =>
      value && value !== 0 ? `grid-template-columns: repeat(${value}, ${props.columnWidth || '1fr'});` : '',
    rows: (value): string =>
      value && value !== 0 ? `grid-template-columns: repeat(${value}, ${props.rowHeight || '1fr'});` : '',
    templateColumns: (value): string => (value ? `grid-template-columns: ${value};` : ''),
    gapX: (value): string => (value ? `grid-column-gap: ${typeof value === 'number' ? `${value}px` : value};` : ''),
    gapY: (value): string => (value ? `grid-row-gap: ${typeof value === 'number' ? `${value}px` : value};` : ''),
  };

  return Object.keys(props).reduce(
    (acc, currentValue): string =>
      options[currentValue] ? `${acc}${options[currentValue](props[currentValue])}` : acc,
    ``,
  );
};

export const getAdaptiveGridStyles = (props: { [key: string]: any }): string => {
  const breakPoints = Object.keys(props).reduce(
    (acc, currentElement) =>
      Object.keys(theme.breakpoints).includes(currentElement)
        ? { ...acc, [currentElement]: props[currentElement] }
        : acc,
    {},
  );

  return Object.keys(breakPoints).reduce((acc, currentBreakpoint) => {
    return `${acc}
      @media (max-width: ${theme.breakpoints[currentBreakpoint]}px) {
        ${buildAdaptiveGridStyles(breakPoints[currentBreakpoint])}
      }
    `;
  }, ``);
};
