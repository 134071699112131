import { FC } from 'react';
import styled from 'styled-components';
import { Icon } from '@components/Icon';
import { Loader } from '@components/Loader';
import { ButtonSize, ButtonVariant, IconButtonProps, StyledButtonProps } from './types';
import { generateButtonStyled } from './utils';

const StyledButton = styled.button<StyledButtonProps & Pick<IconButtonProps, 'iconColor'>>`
  ${({ theme, ...props }): string => generateButtonStyled(props, theme)};

  ${({ iconColor, theme }): string | false =>
    iconColor &&
    `
    color: ${theme.colors[iconColor]};
  `}
`;

export const IconButton: FC<IconButtonProps> = ({
  themeColor = 'malachite',
  type = 'button',
  disabled = false,
  size = ButtonSize.Medium,
  fullWidth = false,
  variant = ButtonVariant.Filled,
  invertedTextColor = false,
  pending = false,
  iconColor,
  className,
  iconName,
  onClick,
  title,
}) => {
  const iconSize = size === ButtonSize.Small ? 12 : 20;

  return (
    <StyledButton
      themeColor={themeColor}
      size={size}
      disabled={disabled}
      fullWidth={fullWidth}
      variant={variant}
      invertedTextColor={invertedTextColor}
      onClick={onClick}
      className={className}
      iconName={iconName}
      iconColor={iconColor}
      pending={pending}
      title={title}
      type={type}
    >
      {pending ? <Loader size={iconSize} /> : <Icon name={iconName} size={iconSize} />}
    </StyledButton>
  );
};
