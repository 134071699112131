import { FC } from 'react';
import styled from 'styled-components';
import { media } from '@styles/utils';
import { Icon } from '@components/Icon';
import { Box } from '@components/Box';
import { Typography, TypographyVariants } from '@components/Typography';
import { ButtonSize, ButtonVariant, Link } from '@components/Button';
import { BlockProps } from '../../types';

const StyledLink = styled(Link)`
  text-decoration: underline;
  text-decoration-style: dashed;
  text-underline-position: under;

  ${media.md`
    position: absolute;
    right: 40px;
    bottom: 10px;
  `};

  ${media.sm`
    right: 20px;
  `};
`;

const StyledTitle = styled(Typography)`
  ${media.sm`
    font-size: 14px;
  `};
`;

const TitleWrapper = styled(Box)`
  border-bottom: 4px solid ${({ themeColor, theme }): string => theme.colors[themeColor]};
`;

type HeaderProps = Pick<BlockProps, 'title' | 'titleIcon' | 'exampleUrl' | 'type'>;

export const Header: FC<HeaderProps> = ({ type, title, exampleUrl, titleIcon }) => {
  const color = type === 'Design' ? 'black' : 'malachite';

  return (
    <Box position="relative" display="flex" alignItems="center" pr={10} pt={5} themeColor={color} sm={{ pr: 5 }}>
      <TitleWrapper
        md={{ width: '100%' }}
        mr="auto"
        pl={10}
        pr={10}
        pb={5}
        sm={{ pl: 5, pr: 5 }}
        display="flex"
        alignItems="center"
        themeColor={color}
      >
        <Icon name={titleIcon} size={40} />
        <Box px={5} md={{ width: '100%', pr: 0 }}>
          <StyledTitle variant={TypographyVariants.BodyMedium} component="h3" uppercase withoutMargin>
            {title}
          </StyledTitle>
        </Box>
      </TitleWrapper>
      <StyledLink href={exampleUrl} themeColor="darkGray" variant={ButtonVariant.Text} size={ButtonSize.Small}>
        Пример
      </StyledLink>
    </Box>
  );
};
