import { TypographyVariants } from '@components/Typography/types';
import { ThemeType } from '@styles/theme';

export const getVariantStyles = (variant: TypographyVariants, theme: ThemeType): string => {
  const commonStyles = `
    margin-top: 0;
    line-height: 1.5;
  `;

  const variantStyles = {
    [TypographyVariants.Title]: `
      ${commonStyles};
      margin-bottom: 1.25em;
      font-size: ${theme.fonts.sizes.lg};
      font-weight: ${theme.fonts.weight.regular};
      line-height: 1.2;
      letter-spacing: 2px;
    `,
    [TypographyVariants.Body]: `
      ${commonStyles};
      font-size: ${theme.fonts.sizes.md};
      font-weight: ${theme.fonts.weight.regular};
    `,
    [TypographyVariants.BodyMedium]: `
      ${commonStyles};
      font-size: ${theme.fonts.sizes.md};
      font-weight: ${theme.fonts.weight.medium};
    `,
    [TypographyVariants.Caption]: `
      ${commonStyles};
      font-size: ${theme.fonts.sizes.sm};
      font-weight: ${theme.fonts.weight.regular};
      letter-spacing: 0.8px;
    `,
    [TypographyVariants.CaptionMedium]: `
      ${commonStyles};
      font-size: ${theme.fonts.sizes.sm};
      font-weight: ${theme.fonts.weight.medium};
      letter-spacing: 0.8px;
    `,
  };

  return variantStyles[variant];
};
