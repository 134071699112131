import { ElementType, FC, ReactText } from 'react';
import styled from 'styled-components';
import { StylesProps, BreakpointsCssProperties } from '@styles/types';
import { getStyles, replacePropsAliases, sortCssBreakpointsProperties } from '@styles/utils';

const Wrapper = styled.div<StylesProps>`
  ${({ theme, themeColor }): string | false => themeColor && `color: ${theme.colors[themeColor]}`};
  ${(props): ReactText => getStyles(props)}
`;

type AdditionalProps = { component?: ElementType; className?: string };

export type BoxProps = StylesProps & BreakpointsCssProperties & AdditionalProps;

export const Box: FC<BoxProps> = ({ children, component, className, ...props }): JSX.Element => (
  <Wrapper as={component} {...sortCssBreakpointsProperties(replacePropsAliases(props))} className={className}>
    {children}
  </Wrapper>
);
