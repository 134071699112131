import { CapabilityData } from './components/Capability';

export const data: Array<CapabilityData> = [
  {
    title: 'Планировочное решение',
    description:
      'Включает в себя план перегородок, дверных и оконных проёмов, а также план расстановки мебели и сантехники в комнатах.',
    icon: '/img/capabilities/capabilities-1.svg',
  },
  {
    title: 'Технические чертежи — 5 шт.',
    description:
      'Включает в себя план перегородок, дверных и оконных проёмов, а также план расстановки мебели и сантехники в комнатах.',
    icon: '/img/capabilities/capabilities-2.svg',
  },
  {
    title: 'Дизайн коллажи',
    description:
      'Включает в себя план перегородок, дверных и оконных проёмов, а также план расстановки мебели и сантехники в комнатах.',
    icon: '/img/capabilities/capabilities-3.png',
    iconRetina: '/img/capabilities/capabilities-3@2x.png',
  },
  {
    title: 'Смета ремонтно-отделочных работ',
    description:
      'Включает в себя план перегородок, дверных и оконных проёмов, а также план расстановки мебели и сантехники в комнатах.',
    icon: '/img/capabilities/capabilities-4.svg',
  },
];
