import { FC } from 'react';
import styled from 'styled-components';
import { BreakpointsCssProperties } from '@styles/types';
import { getAdaptiveStyles, sortCssBreakpointsProperties } from '@styles/utils';
import { GridProps } from './types';
import { getAdaptiveGridStyles } from './utils';

const Wrapper = styled.div<GridProps>`
  display: grid;

  ${({ width }): string | false => width && `width: ${width}`};
  ${({ wrap }): string | false => wrap && `flex-wrap: ${wrap}`};
  ${({ direction }): string | false => direction && `flex-direction: ${direction}`};
  ${({ justify }): string | false => justify && `justify-content: ${justify}`};
  ${({ alignItems }): string | false => alignItems && `align-items: ${alignItems}`};
  ${({ alignContent }): string | false => alignContent && `align-items: ${alignContent}`};

  ${({ columns, columnWidth }): string | false =>
    columns && columns !== 0 && `grid-template-columns: repeat(${columns}, ${columnWidth || '1fr'})`};
  ${({ rows, rowHeight }): string | false =>
    rows && rows !== 0 && `grid-template-rows: repeat(${rows}, ${rowHeight || '1fr'})`};
  ${({ templateColumns }): string | false => templateColumns && `grid-template-columns: ${templateColumns}`};
  ${({ templateRows }): string | false => templateRows && `grid-template-rows: ${templateRows};`};

  ${({ gapX }): string | false => gapX && `grid-column-gap: ${typeof gapX === 'number' ? `${gapX}px` : gapX}`};
  ${({ gapY }): string | false => gapY && `grid-row-gap: ${typeof gapY === 'number' ? `${gapY}px` : gapY}`};

  ${(props): string => getAdaptiveGridStyles(props)};
  ${(props): string => getAdaptiveStyles(props)};
`;

export const Grid: FC<GridProps & BreakpointsCssProperties> = ({ children, className, ...props }): JSX.Element => {
  return (
    <Wrapper {...sortCssBreakpointsProperties(props)} className={className}>
      {children}
    </Wrapper>
  );
};
