import { AnchorHTMLAttributes, ButtonHTMLAttributes, DetailedHTMLProps, HTMLAttributes } from 'react';
import { ColorsTheme } from '@styles/theme';

export enum ButtonSize {
  Small = 'Small',
  Medium = 'Medium',
}

export enum ButtonVariant {
  Contained = 'Contained',
  Filled = 'Filled',
  Text = 'Text',
}

export enum PendingPosition {
  Start = 'Start',
  End = 'End',
  Center = 'Center',
}

type ComponentProps = {
  themeColor?: ColorsTheme;
  size?: ButtonSize;
  fullWidth?: boolean;
  startIcon?: string;
  endIcon?: string;
  variant?: ButtonVariant;
  invertedTextColor?: boolean;
  className?: string;
  withoutShadow?: boolean;
  noWrap?: boolean;
  pending?: boolean;
  pendingPosition?: PendingPosition;
};

type IconButtonComponentProps = Omit<ComponentProps, 'startIcon' | 'endIcon' | 'noWrap'> & { iconName: string };

type ButtonElementProps = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

type LinkElementProps = DetailedHTMLProps<AnchorHTMLAttributes<HTMLLinkElement>, HTMLLinkElement>;

export type ButtonProps = ButtonElementProps & ComponentProps;

export type IconButtonProps = ButtonElementProps & IconButtonComponentProps & { iconColor?: ColorsTheme };

export type IconLinkProps = LinkElementProps & IconButtonComponentProps & { iconColor?: ColorsTheme };

export type LinkProps = LinkElementProps & ComponentProps;

export type StyledProps = Pick<
  ComponentProps,
  'size' | 'fullWidth' | 'variant' | 'themeColor' | 'invertedTextColor' | 'withoutShadow' | 'noWrap' | 'pending'
> &
  Pick<IconButtonComponentProps, 'iconName'>;

export type StyledButtonProps = ButtonElementProps & StyledProps;

export type StyledLinkProps = LinkElementProps & StyledProps;

export type IconWrapperProps = DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> &
  Pick<ButtonProps, 'variant' | 'size'> & { isStart?: boolean; noDividerLine?: boolean };
