import { forwardRef, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Paper } from '@components/Paper';
import { Box } from '@components/Box';
import { Typography, TypographyVariants } from '@components/Typography';
import { media } from '@styles/utils';

export type CapabilityData = {
  title: string;
  description: string;
  icon: string;
  iconRetina?: string;
};

export type CapabilityProps = {
  data: CapabilityData;
  height: number;
  padding: number;
};

export type CapabilityRef = HTMLDivElement;

const Inner = styled(Paper)<{ heightValue: number; isShown: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: ${({ heightValue }): string => `${heightValue}px`};
  border-width: 2px;
  border-style: solid;
  border-color: ${({ theme, isShown }): string => (isShown ? theme.colors.malachite : 'transparent')};

  ${media.lg`
    position: relative;
  `};

  ${media.md`
    min-height: auto;
  `};
`;

const Wrapper = styled.button<{ heightValue: number; isShown: boolean }>`
  position: relative;
  height: ${({ heightValue }): string => `${heightValue}px`};
  color: ${({ theme, isShown }): string => (isShown ? theme.colors.malachite : theme.colors.black)};
  text-align: left;
  background: none;
  border: none;

  &:hover {
    color: ${({ theme }): string => theme.colors.malachite};

    ${Inner} {
      border-color: ${({ theme }): string => theme.colors.malachite};
    }
  }

  &:focus {
    outline: none;
    ${Inner} {
      border-color: ${({ theme }): string => theme.colors.malachite};
    }
  }

  ${media.lg`
      min-height: ${({ heightValue }): string => `${heightValue}px`};
      height: auto;
  `};

  ${media.md`
    min-height: auto;
  `};
`;

const DescriptionWrapper = styled(Box)`
  border-top: 1px solid ${({ theme }): string => theme.colors.gray};
`;

export const Capability = forwardRef<CapabilityRef, CapabilityProps>(
  ({ data: { title, description, icon, iconRetina }, height, padding }, ref) => {
    const [isShown, setIsShown] = useState<boolean>(false);

    const toggle = (): void => {
      setIsShown(!isShown);
    };

    const iconProps = useMemo(
      () => ({
        src: icon,
        alt: title,
        srcSet: iconRetina,
      }),
      [icon, title, iconRetina],
    );

    return (
      <Wrapper onClick={toggle} isShown={isShown} heightValue={height}>
        <Inner heightValue={height} isShown={isShown} customPadding={`${padding}px`}>
          <div ref={ref}>
            <Box pb={5} maxWidth="140px">
              {/* eslint-disable-next-line jsx-a11y/alt-text */}
              <img {...iconProps} />
            </Box>
            <Typography variant={TypographyVariants.BodyMedium} uppercase>
              {title}
            </Typography>
          </div>
          {isShown && (
            <DescriptionWrapper mt={5} pt={5}>
              <Typography variant={TypographyVariants.Caption}>{description}</Typography>
            </DescriptionWrapper>
          )}
        </Inner>
      </Wrapper>
    );
  },
);
