import { FC } from 'react';
import { Layout } from '../common/components/Layout';
import { MainPage } from '../features/MainPage';

const Home: FC = () => {
  return (
    <Layout>
      <MainPage />
    </Layout>
  );
};

export default Home;
