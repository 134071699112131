import { FC } from 'react';
import styled from 'styled-components';
import { Icon } from '@components/Icon';
import { ButtonSize, ButtonVariant, LinkProps, StyledLinkProps } from './types';
import { generateButtonStyled } from './utils';
import { IconWrapper } from './IconWrapper';

const StyledLink = styled.a<StyledLinkProps>`
  ${({ theme, ...props }): string => generateButtonStyled(props, theme)}
`;

export const Link: FC<LinkProps> = ({
  themeColor = 'malachite',
  size = ButtonSize.Medium,
  fullWidth = false,
  variant = ButtonVariant.Filled,
  invertedTextColor = false,
  noWrap = false,
  className,
  startIcon,
  endIcon,
  children,
  href,
  target,
}) => {
  const iconSize = size === ButtonSize.Small ? 12 : 20;
  const StartIcon = startIcon && (
    <IconWrapper isStart>
      <Icon name={startIcon} size={iconSize} />
    </IconWrapper>
  );

  const EndIcon = endIcon && (
    <IconWrapper>
      <Icon name={endIcon} size={iconSize} />
    </IconWrapper>
  );

  return (
    <StyledLink
      themeColor={themeColor}
      size={size}
      href={href}
      fullWidth={fullWidth}
      variant={variant}
      invertedTextColor={invertedTextColor}
      noWrap={noWrap}
      className={className}
      iconName={null}
      target={target}
    >
      {StartIcon}
      {children}
      {EndIcon}
    </StyledLink>
  );
};
