import { FC, useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle } from '@styles/globalStyles';
import { theme } from '@styles/theme';
import '@components/Icon/icons-landing';

export const Layout: FC = ({ children }) => {
  const [svgSprite, setSvgSprite] = useState('');

  useEffect(() => {
    fetch(`/sprite.svg`)
      .then((res) => {
        return res.status === 200 ? res.text() : '';
      })
      .then((data) => {
        setSvgSprite(data);
      });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyle />
        <div style={{ display: 'none' }} dangerouslySetInnerHTML={{ __html: svgSprite }} />
        {children}
      </>
    </ThemeProvider>
  );
};
