import { createGlobalStyle } from 'styled-components';
import { normalize } from 'polished';
import { theme } from '@styles/theme';

export const GlobalStyle = createGlobalStyle`
  ${normalize()};

  *,
  *::before,
  *::after {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
  }

  html,
  body {
    font-family: 'Rubik', sans-serif;
    font-size: ${theme.fonts.sizes.md};
    letter-spacing: 1px;
    color: ${theme.colors.graphite};
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
  }

  body {
    width: 100%;
    min-width: ${theme.breakpoints.xs}px;
    overflow-y: scroll;
  }

  #root {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);

    @media not all and (hover:hover) {
      height:  calc(var(--vh, 1vh) * 100);
    }
  }

  img {
    max-width: 100%;
  }

  figure {
    margin: 0;
  }

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .js-focus-visible :focus:not(.focus-visible) {
    outline: none;

    &:before {
      content: none;
    }
  }
`;
