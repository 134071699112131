import { FC } from 'react';
import styled from 'styled-components';
import { Icon } from '@components/Icon';
import { Loader } from '@components/Loader';
import { ButtonProps, ButtonSize, ButtonVariant, PendingPosition, StyledButtonProps } from './types';
import { generateButtonStyled } from './utils';
import { IconWrapper } from './IconWrapper';
import { LoaderWrapper } from './LoaderWrapper';

const StyledButton = styled.button<StyledButtonProps>`
  ${({ theme, ...props }): string => generateButtonStyled(props, theme)}
`;

export const Button: FC<ButtonProps> = ({
  type = 'button',
  themeColor = 'malachite',
  disabled = false,
  size = ButtonSize.Medium,
  fullWidth = false,
  variant = ButtonVariant.Filled,
  invertedTextColor = false,
  noWrap = false,
  withoutShadow = false,
  pending = false,
  pendingPosition = PendingPosition.Start,
  className,
  startIcon,
  endIcon,
  children,
  onClick,
  title,
}) => {
  const iconSize = size === ButtonSize.Small ? 12 : 20;
  const StartIcon = startIcon && (
    <IconWrapper isStart size={size}>
      <Icon name={startIcon} size={iconSize} />
    </IconWrapper>
  );

  const EndIcon = endIcon && (
    <IconWrapper size={size}>
      <Icon name={endIcon} size={iconSize} />
    </IconWrapper>
  );

  return (
    <StyledButton
      type={type}
      themeColor={themeColor}
      size={size}
      disabled={disabled}
      fullWidth={fullWidth}
      variant={variant}
      invertedTextColor={invertedTextColor}
      onClick={onClick}
      noWrap={noWrap}
      className={className}
      withoutShadow={withoutShadow}
      iconName={null}
      pending={pending}
      title={title}
    >
      {pending && pendingPosition === PendingPosition.Start && (
        <LoaderWrapper position={PendingPosition.Start}>
          <Loader />
        </LoaderWrapper>
      )}
      {pending && pendingPosition === PendingPosition.Center ? (
        <LoaderWrapper position={PendingPosition.Center}>
          <Loader />
        </LoaderWrapper>
      ) : (
        <>
          {StartIcon}
          {children}
          {EndIcon}
        </>
      )}
      {pending && pendingPosition === PendingPosition.End && (
        <LoaderWrapper position={PendingPosition.End}>
          <Loader />
        </LoaderWrapper>
      )}
    </StyledButton>
  );
};
