import { FC, ReactText } from 'react';
import styled from 'styled-components';
import { replacePropsAliases, sortCssBreakpointsProperties, getStyles } from '@styles/utils';
import { TypographyProps, TypographyVariants } from './types';
import { getVariantStyles } from './utils';

const Wrapper = styled.p<TypographyProps & { as?: string }>`
  ${({ variant, theme }): string => getVariantStyles(variant, theme)};
  ${(props): ReactText => getStyles(props)};

  ${({ withoutMargin }): string | false =>
    withoutMargin &&
    `
    margin: 0;
  `};

  ${({ uppercase }): string | false =>
    uppercase &&
    `
    text-transform: uppercase;
  `};

  ${({ as, display }): string | false => as === 'span' && `display: ${display || 'inline-block'}`};
  ${({ noWrap }): string | false => noWrap && `white-space: nowrap`};
  ${({ ellipsis }): string | false => ellipsis && `overflow: hidden; white-space: nowrap; text-overflow: ellipsis`};
  ${({ themeColor, theme }): string => themeColor && `color: ${theme.colors[themeColor]}`}
`;

export const Typography: FC<TypographyProps> = ({
  children,
  variant = TypographyVariants.Body,
  component = 'span',
  withoutMargin,
  className,
  uppercase,
  noWrap,
  ellipsis,
  themeColor,
  ...props
}): JSX.Element => (
  <Wrapper
    as={component}
    variant={variant}
    withoutMargin={withoutMargin}
    uppercase={uppercase}
    noWrap={noWrap}
    ellipsis={ellipsis}
    themeColor={themeColor}
    {...sortCssBreakpointsProperties(replacePropsAliases(props))}
    className={className}
  >
    {children}
  </Wrapper>
);
